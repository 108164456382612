window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

document.addEventListener('DOMContentLoaded', function() {
    const checkAndActivate = () => {
        const activeSlide = document.querySelector('.flow-slide-active');
        if (activeSlide && activeSlide.dataset.slideNumber === '3') { // Angenommen, die Folie 3 hat das data-slide-number Attribut mit dem Wert '3'
            const birthdateField = document.getElementById('birthdate');
            const submitButton = activeSlide.querySelector('[data-flow-next]'); // oder der spezifische Submit-Button Ihrer Folie
            
            function isValidDate(d) {
                return d instanceof Date && !isNaN(d);
            }

            function checkAge() {
                const today = new Date();
                const birthdate = new Date(birthdateField.value);
                const age = today.getFullYear() - birthdate.getFullYear();
                const monthDifference = today.getMonth() - birthdate.getMonth();
                const dayDifference = today.getDate() - birthdate.getDate();

                if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
                    age--;
                }

                return age >= 18;
            }

            birthdateField.addEventListener('input', function() {
                if (isValidDate(new Date(birthdateField.value)) && checkAge()) {
                    submitButton.disabled = false;
                } else {
                    submitButton.disabled = true;
                }
            });
        }
    };

    // Initial check when the page is loaded
    checkAndActivate();

    // Check whenever a new slide becomes active
    const observer = new MutationObserver(checkAndActivate);
    observer.observe(document.body, { attributes: true, childList: true, subtree: true });
});



function generateGUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}



const input = document.createElement('input');
input.type = 'text';
input.name = 'Unique_ID';
input.id = 'Unique_ID';
input.variable  = 'Unique_ID';
input.setAttribute('data-label', 'Unique_ID');
input.value = generateGUID();
input.style = "display:none;"

document.addEventListener('DOMContentLoaded', () => {
    const form = document.getElementsByTagName('form')[0];
    form.appendChild(input);
});